import { Suspense, lazy } from 'react'
import { Navigate, Outlet } from 'react-router'
import PageLoading from '../components/PageLoading'
import AuthLayout from '../layout/AuthLayout'
import DashboardLayout from '../layout/DashboardLayout'
import Login from '../pages/Auth/Login'
import Logout from '../pages/Auth/Logout'
import PrintLabel from '../pages/Orders/TableOrder/PrintLabel'

// Lazy load all page components
const BroadcastMessage = lazy(() => import('../pages/Broadcast/BroadcastMessage'))
const Customer = lazy(() => import('../pages/Customer'))
const Dashboard = lazy(() => import('../pages/Dashboard'))
const DashboardLeads = lazy(() => import('../pages/Leads/DashboardLeads'))
const FormLeads = lazy(() => import('../pages/Leads/FormLeads'))
const LeadsNonForm = lazy(() => import('../pages/Leads/LeadsNonForm'))
const TableLeads = lazy(() => import('../pages/Leads/TableLeads'))
const Addons = lazy(() => import('../pages/Master/Addons'))
const Category = lazy(() => import('../pages/Master/Category'))
const AddCategory = lazy(() => import('../pages/Master/Category/FormCategory/AddCategory'))
const EditCategory = lazy(() => import('../pages/Master/Category/FormCategory/EditCategory'))
const Color = lazy(() => import('../pages/Master/Color'))
const ColorVariant = lazy(() => import('../pages/Master/ColorVariant'))
const Courier = lazy(() => import('../pages/Master/Courier'))
const Designs = lazy(() => import('../pages/Master/Designs'))
const Fiber = lazy(() => import('../pages/Master/Fiber'))
const FiberVariant = lazy(() => import('../pages/Master/FiberVariant'))
const Logo = lazy(() => import('../pages/Master/Logo'))
const Material = lazy(() => import('../pages/Master/Material'))
const MaterialVariant = lazy(() => import('../pages/Master/MaterialVariant'))
const PaymentChannel = lazy(() => import('../pages/Master/PaymentChannel'))
const AddPaymentChannel = lazy(() =>
  import('../pages/Master/PaymentChannel/FormPaymentChannel/AddPaymentChannel')
)
const Prices = lazy(() => import('../pages/Master/Prices'))
const Product = lazy(() => import('../pages/Master/Product'))
const AddProduct = lazy(() => import('../pages/Master/Product/FormProduct/AddProduct'))
const RolePermission = lazy(() => import('../pages/Master/RolePermission'))
const ManagePermission = lazy(() => import('../pages/Master/RolePermission/ManagePermission'))
const ManageStatus = lazy(() => import('../pages/Master/RolePermission/ManageStatus'))
const UserList = lazy(() => import('../pages/Master/User'))
const AddUser = lazy(() => import('../pages/Master/User/FormSection/AddUser'))
const VehicleClass = lazy(() => import('../pages/Master/Vehicle/Class/VehicleClass'))
const Manufacturer = lazy(() => import('../pages/Master/Vehicle/Manufacturer/Manufacturer'))
const AddVehicleModel = lazy(() => import('../pages/Master/Vehicle/Model/Form/AddVehicleModel'))
const EditVehicleModel = lazy(() => import('../pages/Master/Vehicle/Model/Form/EditVehicleModel'))
const VehicleModel = lazy(() => import('../pages/Master/Vehicle/Model/VehicleModel'))
const FormOrder = lazy(() => import('../pages/Orders/FormOrder/FormOrder'))
const TableOrder = lazy(() => import('../pages/Orders/TableOrder'))
const EditOrder = lazy(() => import('../pages/Orders/TableOrder/EditOrder'))
const Advertiser = lazy(() => import('../pages/Report/Advertiser'))
const BudgetDaily = lazy(() => import('../pages/Report/Budget/BudgetDaily'))
const BudgetRatio = lazy(() => import('../pages/Report/Budget/BudgetRatio'))
const Dealmaker = lazy(() => import('../pages/Report/Dealmaker'))
const Finance = lazy(() => import('../pages/Report/Finance'))
const OmzetDaily = lazy(() => import('../pages/Report/OmzetDaily'))
const Progress = lazy(() => import('../pages/Report/Progress'))
const ScoreboardLeague = lazy(() => import('../pages/ScoreboardLeague'))
const ManageMembership = lazy(() => import('../pages/ScoreboardLeague/ManageMembership'))
const Scoreboards = lazy(() => import('../pages/Scoreboards'))
const StatusProduction = lazy(() => import('../pages/StatusProduction'))
const ScoreboardLeagueSeatcover = lazy(() => import('../pages/ScoreboardLeagueSeatcover'))

export const routeList = (isLoggedIn) => [
  {
    path: '/',
    element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/auth/login" />,
    children: [
      {
        subject: 'Menu Dashboard',
        actions: 'read',
        path: '',
        key: '/',
        label: 'Dashboard',
        element: <Suspense fallback={<PageLoading />} children={<Dashboard />} />,
        icon: <i className="fa-solid fa-tv text-[#007F73] w-5" />,
      },
      {
        subject: 'Scoreboard',
        actions: 'read',
        path: '',
        key: '/scoreboard',
        label: 'Scoreboard',
        icon: <i className="fa-solid fa-chart-line bg-transparent" />,
      },
      {
        subject: 'Scoreboard League',
        actions: 'read',
        path: '',
        key: '/scoreboard-league',
        label: 'Scoreboard Liga',
        icon: <i className="fa-solid fa-trophy bg-transparent text-green-500" />,
      },
      {
        subject: 'Scoreboard League',
        actions: 'read',
        path: '',
        key: '/scoreboard-seatcover',
        label: 'Scoreboard Seatcover',
        icon: <i className="fa-solid fa-medal bg-transparent text-yellow-500" />,
      },
      {
        subject: 'Menu Report',
        actions: 'read',
        path: '',
        label: 'Laporan',
        icon: <i className="fa-solid fa-chart-bar text-indigo-500 pl-[1px] w-5" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Report Finance',
            actions: 'read',
            icon: <i className="fa-solid fa-rupiah-sign text-[#1A4D2E] w-5 pl-[2.5px]" />,
            label: 'Keuangan',
            key: '/finance',
            path: 'finance',
            element: <Suspense children={<Finance />} />,
          },
          {
            subject: 'Progress Produksi',
            actions: 'export',
            icon: <i className="fa-solid fa-truck-ramp-box text-[#4F6F52] w-4" />,
            label: 'Progress Produksi',
            key: '/progress',
            path: 'progress',
            element: <Suspense children={<Progress />} />,
          },
          {
            subject: 'Report Budget Daily',
            actions: 'read',
            icon: <i className="fa-solid fa-hand-holding-dollar text-[#948979]" />,
            label: 'Budget Daily',
            key: '/budget-daily',
            path: 'budget-daily',
            element: <Suspense children={<BudgetDaily />} />,
          },
          {
            subject: 'Report Budget Ratio',
            actions: 'read',
            icon: <i className="fa-solid fa-percent text-[#3C5B6F]" />,
            label: 'Budget Ratio',
            key: '/budget-ratio',
            path: 'budget-ratio',
            element: <Suspense children={<BudgetRatio />} />,
          },
          {
            subject: 'Report Advertiser',
            actions: 'read',
            icon: <i className="fa-solid fa-ad text-[#655DBB]" />,
            label: 'Advertiser',
            key: '/advertiser',
            path: 'advertiser',
            element: <Suspense children={<Advertiser />} />,
          },
          {
            subject: 'Report Dealmaker',
            actions: 'read',
            icon: <i className="fa-solid fa-handshake text-[#CE5959]" />,
            label: 'Dealmaker',
            key: '/dealmaker',
            path: 'dealmaker',
            element: <Suspense children={<Dealmaker />} />,
          },
          {
            subject: 'Report Omzet Daily',
            actions: 'read',
            icon: <i className="fa-solid fa-money-bills text-green-500" />,
            label: 'Omzet Daily',
            key: '/omzet-daily',
            path: 'omzet-daily',
            element: <Suspense children={<OmzetDaily />} />,
          },
        ],
      },
      {
        subject: 'Menu Master',
        actions: 'read',
        path: '',
        key: '/master',
        label: 'Master',
        icon: <i className="fa-solid fa-box-open text-blue-500 w-5" />,
        element: <Outlet />,
        children: [
          {
            subject: 'User',
            actions: 'read',
            icon: <i className="fa-regular fa-user w-5 pl-[2.5px]" />,
            label: 'Pengguna',
            key: '/users',
            path: 'users',
            element: <Suspense children={<UserList />} />,
          },
          {
            path: 'users/add',
            element: <Suspense children={<AddUser />} />,
          },
          {
            subject: 'Permission',
            actions: 'manage',
            icon: <i className="fa-solid fa-people-carry-box w-5" />,
            label: 'Role Permissions',
            path: 'roles',
            key: '/roles',
            element: <Suspense children={<RolePermission />} />,
          },
          {
            subject: 'Scoreboard League',
            actions: 'update',
            path: '/league-membership',
            key: '/league-membership',
            label: 'Atur Liga',
            icon: <i className="fa-solid fa-trophy bg-transparent" />,
            element: <Suspense fallback={<PageLoading />} children={<ManageMembership />} />,
          },
          {
            path: 'roles/:id',
            element: <Suspense children={<ManagePermission />} />,
          },
          {
            path: 'role-statuses/:id',
            element: <Suspense children={<ManageStatus />} />,
          },
          {
            subject: 'Payment Channel',
            actions: 'read',
            icon: <i className="fa-solid fa-credit-card w-5" />,
            label: 'Channel Pembayaran',
            path: 'payment-channels',
            key: '/payment-channels',
            element: <Suspense children={<PaymentChannel />} />,
          },
          {
            path: 'payment-channels/add',
            element: <Suspense children={<AddPaymentChannel />} />,
          },
          {
            subject: 'Courier',
            actions: 'read',
            icon: <i className="fa-solid fa-truck-fast w-5" />,
            label: 'Kurir',
            path: 'couriers',
            key: '/couriers',
            element: <Suspense children={<Courier />} />,
          },
          {
            subject: 'Product',
            actions: 'read',
            icon: <i className="fa-solid fa-boxes-stacked w-5 pl-[1px]" />,
            label: 'Master Produk',
            path: 'master-product',
            // key: '/master-product',
            element: <Outlet />,
            children: [
              {
                subject: 'Category',
                actions: 'read',
                icon: <i className="fa-regular fa-rectangle-list w-5 pl-[1px]" />,
                label: 'Kategori',
                path: 'categories',
                key: '/master-product/categories',
                element: <Suspense children={<Category />} />,
              },
              {
                path: 'categories/add',
                element: <Suspense children={<AddCategory />} />,
              },
              {
                path: 'categories/:id',
                element: <Suspense children={<EditCategory />} />,
              },
              {
                subject: 'Manufacturer',
                actions: 'read',
                icon: <i className="fa-solid fa-truck-ramp-box" />,
                label: 'Produk',
                key: 'master-product/products',
                path: 'products',
                element: <Suspense children={<Product />} />,
              },
              {
                path: 'products/add',
                element: <Suspense children={<AddProduct />} />,
              },
              {
                path: 'products/:id/variants',
                element: <Suspense children={<Prices />} />,
              },
              {
                path: 'products/:id/materials',
                element: <Suspense children={<Material />} />,
              },
              {
                path: 'products/:id/material-variants',
                element: <Suspense children={<MaterialVariant />} />,
              },
              {
                path: 'materials/:id/fibers',
                element: <Suspense children={<Fiber />} />,
              },
              {
                path: 'material-variants/:id/fiber-variants',
                element: <Suspense children={<FiberVariant />} />,
              },
              {
                path: 'fibers/:id/colors',
                element: <Suspense children={<Color />} />,
              },
              {
                path: 'fiber-variants/:id/color-variants',
                element: <Suspense children={<ColorVariant />} />,
              },
              {
                path: 'products/:id/addons',
                element: <Suspense children={<Addons />} />,
              },
              {
                path: 'products/:id/designs',
                element: <Suspense children={<Designs />} />,
              },
              {
                path: 'products/:id/logos',
                element: <Suspense children={<Logo />} />,
              },
              {
                subject: 'Vehicle Model',
                actions: 'read',
                icon: <i className="fa-solid fa-truck-monster" />,
                label: 'Tipe Mobil',
                key: 'master-product/vehicle/models',
                path: 'vehicle/models',
                element: <Suspense children={<VehicleModel />} />,
              },
              {
                path: 'vehicle/models/add',
                element: <Suspense children={<AddVehicleModel />} />,
              },
              {
                path: 'vehicle/models/:id',
                element: <Suspense children={<EditVehicleModel />} />,
              },
              {
                subject: 'Vehicle Class',
                actions: 'read',
                icon: <i className="fa-solid fa-car-side" />,
                label: 'Kelas Kendaraan',
                key: 'master-product/vehicle/class',
                path: 'vehicle/class',
                element: <Suspense children={<VehicleClass />} />,
              },
              {
                subject: 'Manufacturer',
                actions: 'read',
                icon: <i className="fa-solid fa-city" />,
                label: 'Pabrikan',
                key: 'master-product/vehicle/manufacturers',
                path: 'vehicle/manufacturers',
                element: <Suspense children={<Manufacturer />} />,
              },
            ],
          },
        ],
      },
      {
        subject: 'Leads',
        actions: 'read',
        label: 'Leads',
        key: 'leads',
        icon: <i className="fa-solid fa-ad ant-menu-item-selected bg-transparent text-pink-500" />,
        element: <Outlet />,
        children: [
          {
            subject: 'Leads',
            actions: 'read',
            icon: <i className="fa-solid fa-tv ant-menu-item-selected" />,
            label: 'Dashboard Leads',
            key: '/leads/dashboard',
            path: 'leads/dashboard',
            element: <Suspense children={<DashboardLeads />} />,
          },
          {
            subject: 'Leads',
            actions: 'read',
            icon: <i className="fa-regular fa-rectangle-list ant-menu-item-selected" />,
            label: 'Leads Form',
            key: '/leads',
            path: 'leads',
            element: <Suspense children={<TableLeads />} />,
          },
          {
            subject: 'Leads Non Form',
            actions: 'read',
            icon: <i className="fa-solid fa-table ant-menu-item-selected" />,
            label: 'Leads Non Form',
            key: '/leads/non-form',
            path: 'leads/non-form',
            element: <Suspense children={<LeadsNonForm />} />,
          },
          {
            path: 'leads/add',
            element: <Suspense children={<FormLeads />} />,
          },
        ],
      },
      {
        subject: 'Customer',
        actions: 'read',
        path: 'customers',
        key: '/customers',
        label: 'Customer',
        element: <Suspense fallback={<PageLoading />} children={<Customer />} />,
        icon: <i className="fa-solid fa-user-group text-base text-orange-500" />,
      },
      {
        subject: 'Order',
        actions: 'create',
        path: 'orders',
        key: '/orders',
        label: 'Form Order',
        element: <Suspense fallback={<PageLoading />} children={<FormOrder />} />,
        icon: <i className="fa-solid fa-laptop-file text-base text-rose-500 w-5" />,
      },
      {
        subject: 'Order',
        actions: 'read',
        path: 'table/orders',
        key: '/table/orders',
        label: 'Table Order',
        element: <Suspense fallback={<PageLoading />} children={<TableOrder />} />,
        icon: <i className="fa-regular fa-rectangle-list text-base text-[#4F6F52] w-5" />,
      },
      {
        path: 'table/orders/:id',
        element: <Suspense fallback={<PageLoading />} children={<EditOrder />} />,
      },
      {
        subject: 'Barcode',
        actions: 'scan',
        path: 'barcode',
        key: '/barcode',
        label: 'Scan Barcode',
        element: <Suspense fallback={<PageLoading />} children={<StatusProduction />} />,
        icon: <i className="fa-solid fa-camera text-base text-cyan-500 w-5" />,
      },
      {
        subject: 'Broadcast',
        actions: 'send',
        path: 'broadcast',
        key: 'broadcast',
        label: 'Broadcast',
        element: <Suspense fallback={<PageLoading />} children={<BroadcastMessage />} />,
        icon: <i className="fa-solid fa-broadcast-tower text-orange-500 w-5" />,
      },
    ],
  },
  {
    path: 'scoreboard',
    element: <Suspense fallback={<PageLoading />} children={<Scoreboards />} />,
  },
  {
    subject: 'Scoreboard League',
    actions: 'read',
    path: 'scoreboard-league',
    element: <Suspense fallback={<PageLoading />} children={<ScoreboardLeague />} />,
  },
  {
    subject: 'Scoreboard League',
    actions: 'read',
    path: 'scoreboard-seatcover',
    element: <Suspense fallback={<PageLoading />} children={<ScoreboardLeagueSeatcover />} />,
  },
  {
    path: 'logout',
    element: <Logout />,
  },
  {
    path: '/print/label/:id',
    element: isLoggedIn ? <PrintLabel /> : <Navigate to="/auth/login" />,
    children: [
      {
        path: 'print/label/:id',
        element: <Suspense children={<PrintLabel />} />,
      },
    ],
  },
  {
    path: '/auth',
    element: !isLoggedIn ? <AuthLayout /> : <Navigate to="/" />,
    children: [
      {
        path: 'login',
        element: <Suspense children={<Login />} />,
      },

      { index: true, element: <Navigate to="/auth/login" /> },
    ],
  },
]
