var packageJson = require('../../package.json')
/**
 * VERSI FLOW EMIRATE
 */

export const FLOW_VERSION = packageJson.version

export const UNIT_SETTINGS = {
  excellent: {
    label: 'EXCELLENT TEAM',
    backgroundColor: '#d23d3d',
    lineColor: '#d23d3d',
    textColor: '#fff',
  },
  great: {
    label: 'GREAT TEAM',
    backgroundColor: '#fc8380',
    lineColor: '#fc8380',
    textColor: '#000',
  },
  elmatador: {
    label: 'EL MATADOR TEAM',
    backgroundColor: '#20409a',
    lineColor: '#20409a',
    textColor: '#efad22',
  },
  seatcover: {
    label: 'WINNER TEAM',
    backgroundColor: '#120202',
    lineColor: '#120202',
    textColor: '#fff',
  },
  force: {
    label: 'FERRARI TEAM',
    backgroundColor: '#ef64f2',
    lineColor: '#ef64f2',
    textColor: '#000',
  },
  glory: {
    label: 'GLORY TEAM',
    backgroundColor: '#F7418F',
    lineColor: '#F7418F',
    textColor: '#fff',
  },
  warriors: {
    label: 'WARRIORS TEAM',
    backgroundColor: '#FF7F00',
    lineColor: '#FF7F00',
    textColor: '#000',
  },
  kopassus_2: {
    label: 'KOPASSUS 2 TEAM',
    backgroundColor: '#5fff5f',
    lineColor: '#5fff5f',
    textColor: '#000',
  },
  kopassus_3: {
    label: 'SPARTAN TEAM',
    backgroundColor: '#00FFFF',
    lineColor: '#00FFFF',
    textColor: '#000',
  },
  champ: {
    label: 'CHAMP TEAM',
    backgroundColor: '#02ccfe',
    lineColor: '#02ccfe',
    textColor: '#000',
  },
  avengers: {
    label: 'AVENGERS TEAM',
    backgroundColor: '#964b00',
    lineColor: '#964b00',
    textColor: '#fff',
  },
  b2b: {
    label: 'B2B TEAM',
    backgroundColor: '#2e795c',
    lineColor: '#2e795c',
    textColor: '#fff',
  },
  funtastic: {
    label: 'FUNTASTIC TEAM',
    backgroundColor: '#FFFF66',
    lineColor: '#FFFF66',
    textColor: '#000',
  },
}

/**
 * UNIT / DIVISI
 */
export const UNIT_GREAT = 1
export const UNIT_EXCELLENT = 2
export const UNIT_FORCE = 3
export const UNIT_WARRIORS = 4
export const UNIT_KOPASSUS_2 = 5
export const UNIT_KOPASSUS_3 = 6
export const UNIT_CHAMP = 7
export const UNIT_SEAT_COVER = 8
export const UNIT_EL_MATADOR = 9
export const UNIT_GLORY = 10
export const UNIT_AVENGERS = 11
export const UNIT_B2B = 12
export const UNIT_FUNTASTIC = 13

/**
 * ROLE
 */
export const ROLE_ADMIN = 1
export const ROLE_DEALMAKER = 2
export const ROLE_SHIPPER = 3
export const ROLE_RESEPER = 4
export const ROLE_POTONG = 5
export const ROLE_JAHIT = 6
export const ROLE_QC = 7
export const ROLE_PACKING = 8
export const ROLE_RESI = 9
export const ROLE_ADMIN_PRODUCTION = 10
export const ROLE_FINANCE = 11
export const ROLE_MARKETING = 12
export const ROLE_CS_SUPERVISOR = 13
// export const ROLE_PENJAHIT_EKSTERNAL = 14
// export const ROLE_ADMIN_PRODUCTION_2 = 15
export const ROLE_CS_CARE = 16
export const ROLE_OPERASIONAL = 17
// export const ROLE_AFFILIATE = 18
export const ROLE_POLA = 22
export const ROLE_POLA_POTONG = 23
export const ROLE_SUPPORT_PRODUKSI = 24
export const ROLE_POLA_HUNTER = 25
export const ROLE_CUSTOMER = 100

/*
  |--------------------------------------------------------------------------
  | CUSTOMER TYPE
  |--------------------------------------------------------------------------
  */
export const CUSTOMER_RETAIL = 1
export const CUSTOMER_AGEN = 2

/*
  |--------------------------------------------------------------------------
  | PAYMENT STATUS
  |--------------------------------------------------------------------------
  */
export const PAYMENT_STATUS_UNPAID = 1
export const PAYMENT_STATUS_DP = 2
export const PAYMENT_STATUS_PAID = 3
export const PAYMENT_STATUS_REFUND = 4
export const PAYMENT_STATUS_CANCEL = 5
export const PAYMENT_STATUS_COD_DP = 6
export const PAYMENT_STATUS_MARKETPLACE = 7
export const PAYMENT_STATUS_COD_FULL = 8
export const PAYMENT_STATUS_BILLING = 18

/**
  |--------------------------------------------------------------------------
  | PRODUCTION STATUS
  |--------------------------------------------------------------------------
 */
export const STATUS_UNVERIFIED = 2
export const STATUS_CETAK_LABEL = 30
export const STATUS_CEK_KESESUAIAN_POLA = 3
export const STATUS_ANTRI_POLA = 4
export const STATUS_POLA_SEDANG_DIBUAT = 6
export const STATUS_ANTRI_POTONG = 7
export const STATUS_ANTRI_JAHIT = 9
export const STATUS_ANTRI_QC = 12
export const STATUS_QC_CHECK = 15
export const STATUS_PROSES_PACKING = 18
export const STATUS_SEDANG_DIKIRIM = 20
export const STATUS_DITERIMA = 21
export const STATUS_CANCEL = 22
export const STATUS_PENDING = 27

export const UNIVERSAL = 173

/**
  |--------------------------------------------------------------------------
  | PLATFORM
  |--------------------------------------------------------------------------
 */
export const PLATFORM_REGULAR = 'Regular'
export const PLATFORM_TOKOPEDIA = 'Tokopedia'
export const PLATFORM_SHOPEE = 'Shopee'
export const PLATFORM_TIKTOK = 'TikTok'
export const PLATFORM_LAZADA = 'Lazada'
export const PLATFORM_COD_NINJA = 'COD Ninja'

/**
  |--------------------------------------------------------------------------
  | CS LEVEL
  |--------------------------------------------------------------------------
  */
export const CS_LEVEL_TRAINING = 1
export const CS_LEVEL_MEGA = 2
export const CS_LEVEL_GIGA = 3
export const CS_LEVEL_TERRA = 4
export const CS_LEVEL_PETA = 5
